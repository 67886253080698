import React from "react"
// nodejs library that concatenates classes
import classNames from "classnames"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

// @material-ui/icons

// core components
import Header from "components/Header/Header.js"
import Footer from "components/Footer/Footer.js"
// import Button from "components/CustomButtons/Button.js"
import HeaderLinks from "components/Header/HeaderLinks.js"
import Parallax from "components/Parallax/Parallax.js"
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import Button from "components/CustomButtons/Button.js"
import Card from "components/Card/Card.js"
import CardBody from "components/Card/CardBody.js"
import CardFooter from "components/Card/CardFooter.js"

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js"

import team1 from "assets/img/faces/avatar.jpg"
import team2 from "assets/img/faces/christian.jpg"
// import team3 from "assets/img/faces/kendall.jpg"

const dashboardRoutes = []

const useStyles = makeStyles(styles)

export default function Selection(props) {
  const classes = useStyles()
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgCard,
    classes.imgFluid
  )
  const { ...rest } = props
  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand="與菲哥追著極光跑"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />
      <Parallax filter image={require("assets/img/landing-bg.jpg")}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title}>黃刀鎮</h1>
              <h4>
                全世界最適合看北極光的的地方非黃刀鎮莫屬。黃刀鎮為主，每年三月和九月於特定時間出團。
                團期從5天4夜到9天8夜不等。
              </h4>
              {/* <br />
              <Button
                color="danger"
                size="lg"
                href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ref=creativetim"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fas fa-play" />
                Watch video
              </Button> */}
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classes.section}>
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <Card>
                <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                  <img src={team1} alt="..." className={imageClasses} />
                </GridItem>
                <h4 className={classes.cardTitle}>
                  5天4夜團
                  <br />
                  <small className={classes.smallTitle}>黃刀鎮</small>
                </h4>
                <CardBody>
                  <p className={classes.description}>
                    You can write here details about one of your team members.
                    You can give more details about what they do. Feel free to
                    add some <a href="#pablo">links</a> for people to be able to
                    follow them outside the site.
                  </p>
                </CardBody>
                <CardFooter className={classes.justifyCenter}>
                  <Button color="primary" className={classes.margin5}>
                    2020
                  </Button>
                  <Button color="primary" className={classes.margin5}>
                    2021
                  </Button>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <Card>
                <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                  <img src={team2} alt="..." className={imageClasses} />
                </GridItem>
                <h4 className={classes.cardTitle}>
                  Blachford原野小木屋
                  <br />
                  <small className={classes.smallTitle}>黃刀鎮</small>
                </h4>
                <CardBody>
                  <p className={classes.description}>
                    You can write here details about one of your team members.
                    You can give more details about what they do. Feel free to
                    add some <a href="#pablo">links</a> for people to be able to
                    follow them outside the site.
                  </p>
                </CardBody>
                <CardFooter className={classes.justifyCenter}>
                  <Button round color="primary" className={classes.margin5}>
                    2020
                  </Button>
                  <Button round color="primary" className={classes.margin5}>
                    2021
                  </Button>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />
    </div>
  )
}
